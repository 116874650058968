<template>
  <div>
    <van-nav-bar
      title="如何推广"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="promotion_tips">
      <van-row style="margin-top:10px">
      <van-col span="24">用户使用您的<b>专属优化地址</b>破解成功后，在车机上自助购买时会自动获得减10元优惠。当用户扫码支付完成，您将获得推荐奖励。</van-col>
    </van-row>

    <van-row style="margin-top:10px">
      <van-col span="24" style="font-weight:bold;">建议推广方式：</van-col>
      <van-col span="24">1.分享车机破解后的效果视频、文章(比如：自定义仪表盘、地图导航、视频音乐播放等）</van-col>
      <van-col span="24">2.分享车机破解教程视频、文章(附效果图)</van-col>
      <van-col span="24">在分享的视频或文章里面，附带上自己的推荐码，告知输入推荐码有优惠</van-col>
    </van-row>

    <van-row style="margin-top:10px">
      <van-col span="24" style="font-weight:bold;">建议推广平台：</van-col>
      <van-col span="24">抖音、快手、车友QQ群、车友微信群、百度贴吧、汽车之家论坛等</van-col>
    </van-row>

    <van-row style="margin-top:10px">
      <van-col span="24" style="font-weight:bold;">参考文案（这是最烂的文案了-_-）：</van-col>
      <van-col span="24">
        <font>无线破解本田中控，无需电脑，操作简单，安全方便</font><br />
        1.停好车，挂P档，拉手刹（请打火操作，以避免电瓶没电导致的错误）<br />
        2.打开手机个人热点<br />
        3.从车机主屏幕，依次点击：设置→系统→详细信息→Wi-Fi，连接手机热点<br />
        4.从车机主屏幕，依次点击：应用列表→浏览器，输入网址 933211.ussss.cn<br />
        5.点击【开始优化】按钮，静等几分钟即可完成优化<br />
        如果网络正常，一般1~2分钟左右就能完成破解<br />
        破解完成后，自动解除了行车视频限制，开车时，用U盘播放视频就不会弹出限制了<br />
        <font color="red"><b>把上面网址 933211 改成你自己的推荐码即可</b></font>
      </van-col>
    </van-row>
    <van-row style="margin-top:10px">
      <van-col span="24" style="font-weight:bold;">上面这种文案一般在论坛都会被删帖的，可以采用分享用车体验或提问的方式，然后再自然地发出您的推荐码。毕竟您是真正的帮助用户获得实惠，利人利己。</van-col>
    </van-row>
    </div>
    
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
export default {
  components: {
    CopyRight,
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.promotion_tips{
  margin: 10px;
  text-align: left;
}
</style>
